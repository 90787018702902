<template>
    <PageWithButtonBelow image>
        <template v-slot:above-title>
            <div class="max-w-xl mx-auto px-4 md:px-6">
                <TinyNavigation :prev="{name: 'matches'}" />
            </div>
        </template>

        <template v-slot:default>
            <div class="max-w-xl mx-auto py-10 text-white text-center">
                <h3 class="heading-1 mb-3">{{ t('page_title') }}</h3>
                <p class="text-sm px-4">{{ t('upload_game_explanation') }}</p>
            </div>
        </template>

        <template v-slot:button>
            <div class="max-w-xl mx-auto">
                <router-link :to="{name: 'match.create.2'}">
                    <span class="block w-full mt-10 rounded-md shadow-sm">
                        <button type="button" class="button w-full">
                            {{ t('add_game') }}
                        </button>
                    </span>
                </router-link>
            </div>
        </template>
    </PageWithButtonBelow>
</template>

<script>
import PageWithButtonBelow from "@/wrappers/PageWithButtonBelow";
import TinyNavigation from "@/components/TinyNavigation";
import useI18n from "@/modules/globalI18n";

export default {
    name: "MatchCreate1",
    components: { PageWithButtonBelow, TinyNavigation },
    setup() {
        return useI18n({
            viewPrefix: "MatchCreate1"
        });
    },
}
</script>