<template>
    <div class="h-full flex flex-col">
        <div class="flex-initial pt-6 -px-8">
            <slot name="above-title" />
        </div>
        <h2 v-if="title" v-text="title" class="text-white font-semibold text-2xl pt-4 pb-4 whitespace-pre-line"></h2>
        <div v-if="image" class="flex-auto flex justify-center min-h-0">
            <img class="object-scale-down h-full" src="@/assets/images/Screenshot_from_2020-10-13_14-30-38.png" alt="">
        </div>
        <div class="py-6" :class="image? 'flex-initial': 'flex-1'">
            <slot />
        </div>
        <div class="flex-initial pb-12">
            <slot name="button" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        image: Boolean
    }
}
</script>