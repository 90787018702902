import { useI18n } from 'vue-i18n';
import axios from 'axios';
import { watch } from "vue";

export default function (options) {
    const { t, locale } = useI18n();

    function changeLocale (newLocale) {
        locale.value = newLocale
    }

    // change locale side effects
    watch(locale, (newLocale) => {
        axios.defaults.headers.common['Accept-Language'] = newLocale
        document.querySelector('html').setAttribute('lang', newLocale)
    })

    // change t to include prefixed selector string
    let prefix = "";
    if (options.viewPrefix) {
        prefix += "views." + options.viewPrefix + ".";
    } else if (options.componentPrefix) {
        prefix += "components." + options.componentPrefix + ".";
    }

    const modifiedT = (...args) => {
        // add prefix to first argument
        if (args[0]){
            args[0] = prefix + args[0]
        }

        return t(...args)
    }

    return { t: modifiedT, locale, changeLocale };
}